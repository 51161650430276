export default {
	name: 'Login',
	data: () => ({
		showlogin: false,
		password: '',
		username: ''
	}),
	computed: {
		failLogin: { get() { return this.$store.getters["Wolf/getFailLogin"]; }, },
		loginNotice: { get() { return this.$store.getters["Wolf/getLoginNotice"]; }, },
		loggedin: { get() { return this.$store.getters["Wolf/getLoggedIn"]; }, }
	},
	methods: {
		runLogin() {
			if (!this.username || !this.password) {
				this.$store.commit('Wolf/SET_LOGINNOTICE', 'Both Username and Password are required.', { root: true })
				this.$store.commit('Wolf/SET_FAILLOGIN', true, { root: true })
			} else {
				this.$store.dispatch('Wolf/processLogin', { u: this.username, p: this.password }, { root: true })
			}
		}
	}
}